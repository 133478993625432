import $ from "jquery";

export class SAMLUtil {
    constructor() { }

    private async _request(url: string, data: any = {}) {
        let request = () => {
            return new Promise((resolve) => {
                $.ajax({
                    url: url,
                    type: "POST",
                    data: data
                }).always(function (res) {
                    resolve(res);
                });
            });
        }

        return await request();
    }

    public parse(metadata: string) {
        let data: any = {};
        try {
            let xml = $($.parseXML(metadata.trim().replace(/md:/gim, "").replace(/ds:/gim, "")));
            data.entity_id = xml.find("EntityDescriptor").attr("entityID");

            data.bindings = {};
            xml.find("[Binding]").each(function () {
                let tag = $(this).prop('tagName');
                let location = $(this).attr('Location');
                if (!data.bindings[tag]) data.bindings[tag] = [];
                data.bindings[tag].push(location);
            });

            data.contact = {};
            xml.find("ContactPerson").each(function () {
                let contactType = $(this).attr('contactType');
                let contact = {};
                contact.name = $(this).find("GivenName").text();
                contact.email = $(this).find("EmailAddress").text();
                data.contact[contactType] = contact;
            });

            if (!data.contact.technical) data.contact.technical = {};
            if (!data.contact.security) data.contact.security = {};

            if (xml.find("IDPSSODescriptor").length > 0) {
                data.service_type = "idp";
                data.cert = {};
                data.cert.text = $(xml.find("IDPSSODescriptor KeyInfo X509Certificate")[0]).text();
                data.url = {};
                data.url.sso = $(xml.find("IDPSSODescriptor SingleSignOnService")[0]).attr('Location');
                data.url.sls = $(xml.find("IDPSSODescriptor SingleLogoutService")[0]).attr('Location');
            } else if (xml.find("SPSSODescriptor").length > 0) {
                data.service_type = "sp";
                data.cert = {};
                data.cert.text = $(xml.find("SPSSODescriptor KeyInfo X509Certificate")[0]).text();
                data.attributes = [];

                xml.find("AttributeConsumingService RequestedAttribute").each(function () {
                    let attr_name = $(this).attr("Name");
                    let attr_required = $(this).attr("isRequired");
                    data.attributes.push({ name: attr_name, required: attr_required });
                });
            }
            
            data.organization_name = xml.find("Organization OrganizationName").text();
            data.service_name = xml.find("AttributeConsumingService ServiceName").text();
            data.service_url = xml.find("mdui\\:InformationURL").text();
            data.privacy_policy_url = xml.find("mdui\\:PrivacyStatementURL").text();
            data.service_description = xml.find("ServiceDescription").text();
        } catch (e) {
            data = {};
        }

        return data;
    }

    public async cert(text: string) {
        let { code, data } = await this._request('/api/keycloud/cert', { text: text });
        if (code == 200)
            return data;
        return {};
    }

    public oid2name(oid: string) {
        return this.ATTRIBUTES[oid];
    }

    public name2oid(name: string) {
        let map: any = this.ATTRIBUTES;
        let name2oid = {};
        for (var key in map) name2oid[map[key]] = key;
        return name2oid[name];
    }

    public attributeList() {
        let map: any = this.ATTRIBUTES;
        let list = [];
        for (var key in map)
            list.push({ oid: key, name: map[key] });
        return list;
    }

    public ATTRIBUTES: any = {
        'urn:oid:0.9.2342.19200300.100.1.1': 'uid',
        'urn:oid:0.9.2342.19200300.100.1.10': 'manager',
        'urn:oid:0.9.2342.19200300.100.1.11': 'documentIdentifier',
        'urn:oid:0.9.2342.19200300.100.1.12': 'documentTitle',
        'urn:oid:0.9.2342.19200300.100.1.13': 'documentVersion',
        'urn:oid:0.9.2342.19200300.100.1.14': 'documentAuthor',
        'urn:oid:0.9.2342.19200300.100.1.15': 'documentLocation',
        'urn:oid:0.9.2342.19200300.100.1.2': 'textEncodedORAddress',
        'urn:oid:0.9.2342.19200300.100.1.20': 'homePhone',
        'urn:oid:0.9.2342.19200300.100.1.21': 'secretary',
        'urn:oid:0.9.2342.19200300.100.1.22': 'otherMailbox',
        'urn:oid:0.9.2342.19200300.100.1.25': 'dc',
        'urn:oid:0.9.2342.19200300.100.1.26': 'aRecord',
        'urn:oid:0.9.2342.19200300.100.1.27': 'mDRecord',
        'urn:oid:0.9.2342.19200300.100.1.28': 'mXRecord',
        'urn:oid:0.9.2342.19200300.100.1.29': 'nSRecord',
        'urn:oid:0.9.2342.19200300.100.1.3': 'mail',
        'urn:oid:0.9.2342.19200300.100.1.30': 'sOARecord',
        'urn:oid:0.9.2342.19200300.100.1.31': 'cNAMERecord',
        'urn:oid:0.9.2342.19200300.100.1.37': 'associatedDomain',
        'urn:oid:0.9.2342.19200300.100.1.38': 'associatedName',
        'urn:oid:0.9.2342.19200300.100.1.39': 'homePostalAddress',
        'urn:oid:0.9.2342.19200300.100.1.4': 'info',
        'urn:oid:0.9.2342.19200300.100.1.40': 'personalTitle',
        'urn:oid:0.9.2342.19200300.100.1.41': 'mobile',
        'urn:oid:0.9.2342.19200300.100.1.42': 'pager',
        'urn:oid:0.9.2342.19200300.100.1.43': 'co',
        'urn:oid:0.9.2342.19200300.100.1.44': 'uniqueIdentifier',
        'urn:oid:0.9.2342.19200300.100.1.45': 'organizationalStatus',
        'urn:oid:0.9.2342.19200300.100.1.46': 'janetMailbox',
        'urn:oid:0.9.2342.19200300.100.1.47': 'mailPreferenceOption',
        'urn:oid:0.9.2342.19200300.100.1.48': 'buildingName',
        'urn:oid:0.9.2342.19200300.100.1.49': 'dSAQuality',
        'urn:oid:0.9.2342.19200300.100.1.5': 'drink',
        'urn:oid:0.9.2342.19200300.100.1.50': 'singleLevelQuality',
        'urn:oid:0.9.2342.19200300.100.1.51': 'subtreeMinimumQuality',
        'urn:oid:0.9.2342.19200300.100.1.52': 'subtreeMaximumQuality',
        'urn:oid:0.9.2342.19200300.100.1.53': 'personalSignature',
        'urn:oid:0.9.2342.19200300.100.1.54': 'dITRedirect',
        'urn:oid:0.9.2342.19200300.100.1.55': 'audio',
        'urn:oid:0.9.2342.19200300.100.1.56': 'documentPublisher',
        'urn:oid:0.9.2342.19200300.100.1.6': 'roomNumber',
        'urn:oid:0.9.2342.19200300.100.1.60': 'jpegPhoto',
        'urn:oid:0.9.2342.19200300.100.1.7': 'photo',
        'urn:oid:0.9.2342.19200300.100.1.8': 'userClass',
        'urn:oid:0.9.2342.19200300.100.1.9': 'host',
        'urn:oid:1.2.840.113549.1.9.1': 'email',
        'urn:oid:1.2.752.194.10.2.2': 'sisSchoolGrade',
        'urn:oid:1.2.752.194.10.2.1': 'sisLegalGuardianFor',
        'urn:oid:1.2.752.194.10.3': 'sisOrgDepartment',
        'urn:oid:1.2.752.194.10.2.4': 'sisSchoolUnitCode',
        'urn:oid:1.3.6.1.4.1.2428.90.1.1': 'norEduOrgUniqueNumber',
        'urn:oid:1.3.6.1.4.1.2428.90.1.11': 'norEduOrgSchemaVersion',
        'urn:oid:1.3.6.1.4.1.2428.90.1.12': 'norEduOrgNIN',
        'urn:oid:1.3.6.1.4.1.2428.90.1.2': 'norEduOrgUnitUniqueNumber',
        'urn:oid:1.3.6.1.4.1.2428.90.1.3': 'norEduPersonBirthDate',
        'urn:oid:1.3.6.1.4.1.2428.90.1.4': 'norEduPersonLIN',
        'urn:oid:1.3.6.1.4.1.2428.90.1.5': 'norEduPersonNIN',
        'urn:oid:1.3.6.1.4.1.2428.90.1.6': 'norEduOrgAcronym',
        'urn:oid:1.3.6.1.4.1.2428.90.1.7': 'norEduOrgUniqueIdentifier',
        'urn:oid:1.3.6.1.4.1.2428.90.1.8': 'norEduOrgUnitUniqueIdentifier',
        'urn:oid:1.3.6.1.4.1.2428.90.1.9': 'federationFeideSchemaVersion',
        'urn:oid:1.3.6.1.4.1.24552.500.1.1.1.13': 'sshPublicKey',
        'urn:oid:1.3.6.1.4.1.250.1.57': 'labeledURI',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.1': 'eduPersonAffiliation',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.11': 'eduPersonAssurance',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.10': 'eduPersonTargetedID',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.13': 'eduPersonUniqueId',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.16': 'eduPersonOrcid',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.2': 'eduPersonNickname',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.3': 'eduPersonOrgDN',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.4': 'eduPersonOrgUnitDN',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.5': 'eduPersonPrimaryAffiliation',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.6': 'eduPersonPrincipalName',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.7': 'eduPersonEntitlement',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.8': 'eduPersonPrimaryOrgUnitDN',
        'urn:oid:1.3.6.1.4.1.5923.1.1.1.9': 'eduPersonScopedAffiliation',
        'urn:oid:1.3.6.1.4.1.5923.1.2.1.2': 'eduOrgHomePageURI',
        'urn:oid:1.3.6.1.4.1.5923.1.2.1.3': 'eduOrgIdentityAuthNPolicyURI',
        'urn:oid:1.3.6.1.4.1.5923.1.2.1.4': 'eduOrgLegalName',
        'urn:oid:1.3.6.1.4.1.5923.1.2.1.5': 'eduOrgSuperiorURI',
        'urn:oid:1.3.6.1.4.1.5923.1.2.1.6': 'eduOrgWhitePagesURI',
        'urn:oid:1.3.6.1.4.1.5923.1.5.1.1': 'isMemberOf',
        'urn:oid:1.3.6.1.4.1.25178.1.2.1': 'schacMotherTongue',
        'urn:oid:1.3.6.1.4.1.25178.1.2.2': 'schacGender',
        'urn:oid:1.3.6.1.4.1.25178.1.2.3': 'schacDateOfBirth',
        'urn:oid:1.3.6.1.4.1.25178.1.2.4': 'schacPlaceOfBirth',
        'urn:oid:1.3.6.1.4.1.25178.1.2.5': 'schacCountryOfCitizenship',
        'urn:oid:1.3.6.1.4.1.25178.1.2.6': 'schacSn1',
        'urn:oid:1.3.6.1.4.1.25178.1.2.7': 'schacSn2',
        'urn:oid:1.3.6.1.4.1.25178.1.2.8': 'schacPersonalTitle',
        'urn:oid:1.3.6.1.4.1.25178.1.2.9': 'schacHomeOrganization',
        'urn:oid:1.3.6.1.4.1.25178.1.2.10': 'schacHomeOrganizationType',
        'urn:oid:1.3.6.1.4.1.25178.1.2.11': 'schacCountryOfResidence',
        'urn:oid:1.3.6.1.4.1.25178.1.2.12': 'schacUserPresenceID',
        'urn:oid:1.3.6.1.4.1.25178.1.2.13': 'schacPersonalPosition',
        'urn:oid:1.3.6.1.4.1.25178.1.2.14': 'schacPersonalUniqueCode',
        'urn:oid:1.3.6.1.4.1.25178.1.2.15': 'schacPersonalUniqueID',
        'urn:oid:1.3.6.1.4.1.25178.1.2.17': 'schacExpiryDate',
        'urn:oid:1.3.6.1.4.1.25178.1.2.18': 'schacUserPrivateAttribute',
        'urn:oid:1.3.6.1.4.1.25178.1.2.19': 'schacUserStatus',
        'urn:oid:1.3.6.1.4.1.25178.1.2.20': 'schacProjectMembership',
        'urn:oid:1.3.6.1.4.1.25178.1.2.21': 'schacProjectSpecificRole',
        'urn:oid:2.16.840.1.113730.3.1.1': 'carLicense',
        'urn:oid:2.16.840.1.113730.3.1.2': 'departmentNumber',
        'urn:oid:2.16.840.1.113730.3.1.216': 'userPKCS12',
        'urn:oid:2.16.840.1.113730.3.1.241': 'displayName',
        'urn:oid:2.16.840.1.113730.3.1.3': 'employeeNumber',
        'urn:oid:2.16.840.1.113730.3.1.39': 'preferredLanguage',
        'urn:oid:2.16.840.1.113730.3.1.4': 'employeeType',
        'urn:oid:2.16.840.1.113730.3.1.40': 'userSMIMECertificate',
        'urn:oid:2.5.4.0': 'objectClass',
        'urn:oid:2.5.4.1': 'aliasedObjectName',
        'urn:oid:2.5.4.10': 'o',
        'urn:oid:2.5.4.11': 'ou',
        'urn:oid:2.5.4.12': 'title',
        'urn:oid:2.5.4.13': 'description',
        'urn:oid:2.5.4.14': 'searchGuide',
        'urn:oid:2.5.4.15': 'businessCategory',
        'urn:oid:2.5.4.16': 'postalAddress',
        'urn:oid:2.5.4.17': 'postalCode',
        'urn:oid:2.5.4.18': 'postOfficeBox',
        'urn:oid:2.5.4.19': 'physicalDeliveryOfficeName',
        'urn:oid:2.5.4.2': 'knowledgeInformation',
        'urn:oid:2.5.4.20': 'telephoneNumber',
        'urn:oid:2.5.4.21': 'telexNumber',
        'urn:oid:2.5.4.22': 'teletexTerminalIdentifier',
        'urn:oid:2.5.4.23': 'facsimileTelephoneNumber',
        'urn:oid:2.5.4.24': 'x121Address',
        'urn:oid:2.5.4.25': 'internationaliSDNNumber',
        'urn:oid:2.5.4.26': 'registeredAddress',
        'urn:oid:2.5.4.27': 'destinationIndicator',
        'urn:oid:2.5.4.28': 'preferredDeliveryMethod',
        'urn:oid:2.5.4.29': 'presentationAddress',
        'urn:oid:2.5.4.3': 'cn',
        'urn:oid:2.5.4.30': 'supportedApplicationContext',
        'urn:oid:2.5.4.31': 'member',
        'urn:oid:2.5.4.32': 'owner',
        'urn:oid:2.5.4.33': 'roleOccupant',
        'urn:oid:2.5.4.34': 'seeAlso',
        'urn:oid:2.5.4.35': 'userPassword',
        'urn:oid:2.5.4.36': 'userCertificate',
        'urn:oid:2.5.4.37': 'cACertificate',
        'urn:oid:2.5.4.38': 'authorityRevocationList',
        'urn:oid:2.5.4.39': 'certificateRevocationList',
        'urn:oid:2.5.4.4': 'sn',
        'urn:oid:2.5.4.40': 'crossCertificatePair',
        'urn:oid:2.5.4.41': 'name',
        'urn:oid:2.5.4.42': 'givenName',
        'urn:oid:2.5.4.43': 'initials',
        'urn:oid:2.5.4.44': 'generationQualifier',
        'urn:oid:2.5.4.45': 'x500UniqueIdentifier',
        'urn:oid:2.5.4.46': 'dnQualifier',
        'urn:oid:2.5.4.47': 'enhancedSearchGuide',
        'urn:oid:2.5.4.48': 'protocolInformation',
        'urn:oid:2.5.4.49': 'distinguishedName',
        'urn:oid:2.5.4.5': 'serialNumber',
        'urn:oid:2.5.4.50': 'uniqueMember',
        'urn:oid:2.5.4.51': 'houseIdentifier',
        'urn:oid:2.5.4.52': 'supportedAlgorithms',
        'urn:oid:2.5.4.53': 'deltaRevocationList',
        'urn:oid:2.5.4.54': 'dmdName',
        'urn:oid:2.5.4.6': 'c',
        'urn:oid:2.5.4.65': 'pseudonym',
        'urn:oid:2.5.4.7': 'l',
        'urn:oid:2.5.4.8': 'st',
        'urn:oid:2.5.4.9': 'street',
        'urn:oid:1.3.6.1.4.1.14305.1.10.1.4.3': 'koCommonName',
        'urn:oid:1.3.6.1.4.1.14305.1.10.1.4.22': 'koGivenName',
        'urn:oid:1.3.6.1.4.1.14305.1.10.1.4.4': 'koSurname',
        'urn:oid:1.3.6.1.4.1.14305.1.10.1.4.10': 'koOrganizationName',
        'urn:oid:1.3.6.1.4.1.14305.1.10.1.4.11': 'koOrganizationUnitName',
        'urn:oid:1.3.6.1.4.1.14305.1.10.1.4.12': 'koOrganizationCode',
        'urn:oid:1.3.6.1.4.1.14305.1.10.1.1.39': 'koHomePostalAddress',
        'urn:oid:1.3.6.1.4.1.14305.1.10.1.4.16': 'koPostalAddress',
        'urn:oid:1.3.6.1.4.1.14305.1.10.1.1.16': 'koResearcherNumber'
    };
}

export default SAMLUtil;