import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [`

/* file: /var/www/trusthub/branch/main/build/src/app/page.mypage/view.scss */
.container {
  max-width: 720px;
}

.content-page-header {
  display: flex;
  align-items: center;
  padding: 18px 16px;
  padding-top: 0;
}
.content-page-header * {
  margin: 0;
  line-height: 1;
}

.card-body {
  border: none;
}

.alert {
  font-size: 16px;
}

.wiz-form .wiz-form-rows:first-child {
  border-top: none;
}

.wiz-form .wiz-form-rows:last-child {
  border-bottom: none;
}`],
})
export class PageMypageComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public data: any = {};
    public attrs: any = [];

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/auth/login");
        await this.load();
    }

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "Confirm",
            status: status
        });
    }

    public async load() {
        const { data } = await wiz.call("session");
        let { user } = data;
        this.data.user = user;
        this.data.password = {};

        let res: any = await wiz.call("attributes");
        this.attrs = res.data;

        console.log(this.data.user.meta);

        await this.service.render();
    }

    public async update() {
        let userinfo = JSON.stringify(this.data.user);
        const { code, data } = await wiz.call("update", { userinfo });
        if (code == 200) {
            this.alert("Updated", 'success');
            await this.load();
            await this.service.auth.init();
            return;
        }

        await this.alert("Error");
    }

    public async changePassword() {
        let pdata = JSON.parse(JSON.stringify(this.data.password));

        if (pdata.data != pdata.repeat) {
            await this.alert("Check password");
            return;
        }

        let pd = {};
        pd.current = this.service.auth.hash(pdata.current);
        pd.data = this.service.auth.hash(pdata.data);

        const { code, data } = await wiz.call("change_password", pd);

        if (code != 200) {
            await this.alert(data);
            return;
        }

        location.href = "/auth/logout";
    }

}

export default PageMypageComponent;