import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.services.item');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-services-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/trusthub/branch/main/build/src/app/page.admin.services.item/view.scss */
.btn-group {
  box-shadow: none;
}
.btn-group .btn {
  min-width: 120px;
  height: 36px;
}

.col,
.col-auto {
  align-items: center;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.main-container .container {
  border-right: 1px solid var(--wc-border);
}
.main-container .header {
  border-bottom: 1px solid var(--wc-border);
}
.main-container .context {
  flex: 1;
  overflow: auto;
}

.container {
  max-width: 100%;
}

.menu-container {
  max-width: 1024px;
  margin: 0 auto;
}`],
})
export class PageAdminServicesItemComponent implements OnInit {
    public TYPE: string = '';
    public ID: string = '';
    public MENU: string = '';

    public loaded: boolean = false;
    public info: any = {};

    constructor(@Inject( Service) public service: Service) {
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        this.ID = WizRoute.segment.id ? WizRoute.segment.id : '';
        this.MENU = WizRoute.segment.menu ? WizRoute.segment.menu : '';
        if (!this.ID) return service.href(`/admin/services/${this.TYPE}`);
        if (!this.MENU) return service.href(`/admin/services/${this.TYPE}/${this.ID}/info`);
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async ngDoCheck() {
        let preID = this.ID;
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        this.ID = WizRoute.segment.id ? WizRoute.segment.id : '';
        this.MENU = WizRoute.segment.menu ? WizRoute.segment.menu : '';
        if (!this.ID) return this.service.href(`/admin/services/${this.TYPE}`);
        if (!this.MENU) return this.service.href(`/admin/services/${this.TYPE}/${this.ID}/info`);
        if (preID != this.ID)
            await this.load();
    }

    public async load() {
        this.loaded = false;
        this.info = {};
        await this.service.render();

        const { code, data } = await wiz.call("info", {
            type: this.TYPE,
            id: this.ID
        });

        if (code == 200) {
            this.info = data;
        } else {
            this.info = {
                servicetype: this.ID
            };
        }

        this.loaded = true;
        await this.service.render();
    }

    public is(tab: string) {
        return this.MENU == tab;
    }

    public isnot(tab: string) {
        return this.MENU != tab;
    }

    public async active(tab: string) {
        this.service.href(`/admin/services/${this.TYPE}/${this.ID}/${tab}`);
    }
}

export default PageAdminServicesItemComponent;