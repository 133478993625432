import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /var/www/trusthub/branch/main/build/src/app/page.main/view.scss */
.view-container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.view-container .container {
  max-width: 1280px;
  min-height: 100%;
  display: flex;
  align-items: center;
}
.view-container .view-header {
  margin-bottom: 32px;
}
.view-container .view-header h2 {
  margin: 0;
  padding: 0;
  color: var(--wc-blue);
}
.view-container .view-context {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
}
.view-container .view-context h2 {
  color: var(--wc-blue);
  font-size: 36px;
  margin-bottom: 20px;
}
.view-container .view-context > div {
  margin-bottom: 12px;
}
.view-container .view-context .text-subtitle {
  color: var(--wc-text);
}
.view-container .view-context .text-description {
  color: var(--wc-blue);
  margin-bottom: 0;
}
.view-container .view-context .text-featured {
  color: var(--wc-text);
  font-size: 16px;
  padding: 8px 12px;
  background-color: var(--wc-light);
  border-radius: 12px;
  margin-top: 8px;
  margin-bottom: 16px;
}
.view-container .view-context .text-featured ul {
  margin: 0;
}
.view-container .view-context .login-btn {
  width: 100%;
  max-width: 280px;
  font-size: 20px;
}

.logo {
  width: auto;
  height: 64px;
}

.main-image {
  text-align: center;
}
.main-image img {
  width: 70%;
}`],
})
export class PageMainComponent implements OnInit {
    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/mypage");
    }

}

export default PageMainComponent;