import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.service.info.oidc');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-service-info-oidc',
template: templateSource || '',
    styles: [`

/* file: /var/www/trusthub/branch/main/build/src/app/portal.keycloud.service.info.oidc/view.scss */
.editor {
  height: 300px;
  border-left: 1px solid var(--wiz-color-border);
}

.row {
  margin: 0;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.card {
  border-radius: 0;
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn {
  height: 36px;
}
.btn.btn-sm {
  height: 18px;
}`],
})
export class PortalKeycloudServiceInfoOidcComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() info: any;

    public loaded: boolean = false;
    public monacoEditor: any;

    public scopes: any = {};
    public redirect_uri: any = "";
    public post_logout_redirect_uri: any = "";

    public monacoopts: any = {
        value: '',
        language: 'json',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        minimap: {
            enabled: false
        }
    }

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.src) this.info.src = {};
        this.scopes = {};
        if (this.info.src.scopes)
            for (let i = 0; i < this.info.src.scopes.length; i++)
                this.scopes[this.info.src.scopes[i]] = true;
        this.loaded = true;
        await this.service.render();
    }

    public async init(event: any) {
        let { editor } = event;
        this.monacoEditor = editor;
        if (this.info.src && this.info.src.issuer)
            this.monacoEditor.updateOptions({ readOnly: true });
    }

    public async addRedirectUri() {
        if (!this.redirect_uri) return;
        this.info.src.redirect_uri.push(this.redirect_uri);
        this.redirect_uri = "";
        await this.service.render();
    }

    public async addPostLogoutRedirectUri() {
        if (!this.post_logout_redirect_uri) return;
        this.info.src.post_logout_redirect_uri.push(this.post_logout_redirect_uri);
        this.post_logout_redirect_uri = "";
        await this.service.render();
    }

    public async update() {
        this.info.name = this.info.src.name;
        this.info.src.scopes = [];
        for (let key in this.scopes)
            if (this.scopes[key])
                this.info.src.scopes.push(key)

        const { data } = await wiz.call("update", { data: JSON.stringify(this.info) });
        if (!this.info.id)
            return location.href = `/admin/services/sp/${data}`;
    }

    public async delete() {
        let res = await this.service.alert.show({
            title: 'Delete OIDC RP',
            message: 'Are you sure?',
            cancel: 'Cancel',
            actionBtn: 'error',
            action: 'Delete',
            status: 'error'
        });

        if (!res) return;

        await wiz.call("delete", { data: JSON.stringify(this.info) });
        this.service.href("/admin/services/sp");
    }
}

export default PortalKeycloudServiceInfoOidcComponent;