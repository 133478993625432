import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.settings.metarefresh');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-settings-metarefresh',
template: templateSource || '',
    styles: [`

/* file: /var/www/trusthub/branch/main/build/src/app/page.admin.settings.metarefresh/view.scss */
tr, td {
  vertical-align: middle;
}

.workspace-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: rgba(116, 108, 112, 0.1);
}
.workspace-loading table {
  width: 100%;
  height: 100%;
}
.workspace-loading table td {
  height: 100%;
  vertical-align: middle;
  text-align: center;
}
.workspace-loading table td .spinner-border {
  width: 48px;
  height: 48px;
}`],
})
export class PageAdminSettingsMetarefreshComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
    }

    public metadata: any = { data: [], create: {} };
}

export default PageAdminSettingsMetarefreshComponent;