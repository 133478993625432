import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.idp.info.oidc');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-idp-info-oidc',
template: templateSource || '',
    styles: [`

/* file: /var/www/trusthub/branch/main/build/src/app/portal.keycloud.idp.info.oidc/view.scss */
.editor {
  height: 300px;
  border-left: 1px solid var(--wiz-color-border);
}

.row {
  margin: 0;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.card {
  border-radius: 0;
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn {
  height: 36px;
}
.btn.btn-sm {
  height: 18px;
}`],
})
export class PortalKeycloudIdpInfoOidcComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() info: any;

    public loaded: boolean = false;
    public monacoEditor: any;

    public monacoopts: any = {
        value: '',
        language: 'json',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        minimap: {
            enabled: false
        }
    }

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.src) this.info.src = {};
        if (!this.info.src.wellknown) this.info.src.wellknown = '';
        this.loaded = true;
        await this.service.render();
    }

    public async init(event: any) {
        let { editor } = event;
        this.monacoEditor = editor;
        if (this.info.src && this.info.src.entity_id)
            this.monacoEditor.updateOptions({ readOnly: true });
    }

    public async parse() {
        let wellknown: any = this.info.src.wellknown;
        let wellknownstr: any = this.info.src.wellknown + '';

        try {
            wellknown = JSON.parse(wellknown);
            this.info.src = wellknown;
            this.info.src.wellknown = wellknownstr;
        } catch (e) {
            await this.service.alert.show({
                title: 'Invalid JSON',
                message: 'Please set valid well-known JSON.',
                cancel: false,
                actionBtn: 'error',
                action: 'Confirm',
                status: 'error'
            });
            return;
        }

        await this.service.render();
    }

    public async update() {
        const { data } = await wiz.call("update", { data: JSON.stringify(this.info) });
        if (!this.info.id)
            return location.href = `/admin/services/idp/${data}`;
    }

    public async delete() {
        let res = await this.service.alert.show({
            title: 'Delete OIDC OP',
            message: 'Are you sure?',
            cancel: 'Cancel',
            actionBtn: 'error',
            action: 'Delete',
            status: 'error'
        });

        if (!res) return;

        await wiz.call("delete", { data: JSON.stringify(this.info) });
        this.service.href("/admin/services/idp");
    }
}

export default PortalKeycloudIdpInfoOidcComponent;