import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.attributemap');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-attributemap',
template: templateSource || '',
    styles: [`

/* file: /var/www/trusthub/branch/main/build/src/app/portal.keycloud.attributemap/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn {
  width: 32px;
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}

.btn.btn-sm {
  width: auto !important;
  height: 24px !important;
  padding: 0 6px !important;
}

.nowrap {
  white-space: nowrap;
}`],
})
export class PortalKeycloudAttributemapComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() info: any;

    public loaded: boolean = false;
    public list: any = [];

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async load() {
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search");
        if (code != 200) return;
        this.list = [];

        let keys: any = {};
        for (let i = 0; i < this.info.attrmap.length; i++)
            keys[this.info.attrmap[i].attrname] = this.info.attrmap[i];

        for (let i = 0; i < data.length; i++) {
            if (!keys[data[i].attrname]) {
                this.info.attrmap.push({ attrname: data[i].attrname, translate: data[i][this.info.servicetype], required: data[i].required });
            }
        }

        this.loaded = true;
        await this.service.render();
    }

    public async update(item: any) {
        item.required = item.required ? 1 : 0;
        const { code } = await wiz.call("update", item);
        if (code == 200) {
            this.newItem = null;
            this.load();
        }
        await this.service.render();
    }

    public async remove(item: any) {
        await wiz.call("delete", item);
        this.load();
        await this.service.render();
    }
}

export default PortalKeycloudAttributemapComponent;