import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.idp.info.saml');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { SAMLUtil } from "src/libs/portal/keycloud/util/saml";

@Component({
    selector: 'wiz-portal-keycloud-idp-info-saml',
template: templateSource || '',
    styles: [`

/* file: /var/www/trusthub/branch/main/build/src/app/portal.keycloud.idp.info.saml/view.scss */
.editor {
  height: 300px;
  border-left: 1px solid var(--wiz-color-border);
}

.row {
  margin: 0;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.card {
  border-radius: 0;
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn {
  height: 36px;
}
.btn.btn-sm {
  height: 18px;
}`],
})
export class PortalKeycloudIdpInfoSamlComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() info: any;

    public loaded: boolean = false;
    public samlUtil: SAMLUtil = new SAMLUtil();
    public monacoEditor: any;

    public monacoopts: any = {
        value: '',
        language: 'html',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        minimap: {
            enabled: false
        }
    }

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.src) this.info.src = {};
        if (!this.info.src.metadata) this.info.src.metadata = '';
        this.loaded = true;
        await this.service.render();
    }

    public async init(event: any) {
        let { editor } = event;
        this.monacoEditor = editor;
        if (this.info.src && this.info.src.entity_id)
            this.monacoEditor.updateOptions({ readOnly: true });
    }

    public async parse() {
        let util: SAMLUtil = this.samlUtil;
        let metadata = util.parse(this.info.src.metadata);
        if (metadata.service_type != 'idp') {
            await this.service.alert.show({
                title: 'Invalid Metadata',
                message: 'Please set valid IdP metadata.',
                cancel: false,
                actionBtn: 'error',
                action: 'Confirm',
                status: 'error'
            });
            return;
        }
        metadata.metadata = this.info.src.metadata;
        this.info.src = metadata;
        this.info.name = metadata.service_name;
        this.info.servicetype = 'saml';
        this.info.key = metadata.entity_id;
        this.info.src.cert = await util.cert(this.info.src.cert.text);

        if (this.monacoEditor)
            this.monacoEditor.updateOptions({ readOnly: true });

        await this.service.render();
    }

    public async update() {
        this.info.name = this.info.src.service_name;
        const { data } = await wiz.call("update", { data: JSON.stringify(this.info) });
        if (!this.info.id)
            return location.href = `/admin/services/idp/${data}`;
    }

    public async delete() {
        let res = await this.service.alert.show({
            title: 'Delete SAML SP',
            message: 'Are you sure?',
            cancel: 'Cancel',
            actionBtn: 'error',
            action: 'Delete',
            status: 'error'
        });

        if (!res) return;

        await wiz.call("delete", { data: JSON.stringify(this.info) });
        this.service.href("/admin/services/idp");
    }
}

export default PortalKeycloudIdpInfoSamlComponent;