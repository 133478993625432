import '@angular/compiler';
import { PortalKeycloudIdpInfoSamlComponent } from './portal.keycloud.idp.info.saml/portal.keycloud.idp.info.saml.component';
import { ComponentNavbarBaseComponent } from './component.navbar.base/component.navbar.base.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';
import { PortalKeycloudAttributemapComponent } from './portal.keycloud.attributemap/portal.keycloud.attributemap.component';
import { LayoutSidebarComponent } from './layout.sidebar/layout.sidebar.component';
import { PageAdminSettingsAttributesComponent } from './page.admin.settings.attributes/page.admin.settings.attributes.component';
import { PageAdminUsersComponent } from './page.admin.users/page.admin.users.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { ComponentSidebarComponent } from './component.sidebar/component.sidebar.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalKeycloudIdpInfoOidcComponent } from './portal.keycloud.idp.info.oidc/portal.keycloud.idp.info.oidc.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalKeycloudServiceInfoSamlComponent } from './portal.keycloud.service.info.saml/portal.keycloud.service.info.saml.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PageAdminSettingsAccesslogComponent } from './page.admin.settings.accesslog/page.admin.settings.accesslog.component';
import { PageAdminServicesItemComponent } from './page.admin.services.item/page.admin.services.item.component';
import { PageAdminServicesComponent } from './page.admin.services/page.admin.services.component';
import { PageAdminSettingsMetarefreshComponent } from './page.admin.settings.metarefresh/page.admin.settings.metarefresh.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalKeycloudServiceInfoOidcComponent } from './portal.keycloud.service.info.oidc/portal.keycloud.service.info.oidc.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

@NgModule({
    declarations: [
        AppComponent,
        PortalKeycloudIdpInfoSamlComponent,
        ComponentNavbarBaseComponent,
        PageMainComponent,
        PortalSeasonUiDropdownComponent,
        PortalSeasonLoadingDefaultComponent,
        PortalKeycloudAttributemapComponent,
        LayoutSidebarComponent,
        PageAdminSettingsAttributesComponent,
        PageAdminUsersComponent,
        PortalSeasonTabComponent,
        PortalSeasonViewerTreeComponent,
        PortalSeasonPagenationComponent,
        LayoutNavbarComponent,
        ComponentSidebarComponent,
        PortalSeasonAlertComponent,
        PortalKeycloudIdpInfoOidcComponent,
        LayoutEmptyComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalKeycloudServiceInfoSamlComponent,
        PageAuthenticateComponent,
        PageAdminSettingsAccesslogComponent,
        PageAdminServicesItemComponent,
        PageAdminServicesComponent,
        PageAdminSettingsMetarefreshComponent,
        PageDashboardComponent,
        PortalSeasonLoadingHexaComponent,
        PortalSeasonStatusbarComponent,
        PortalKeycloudServiceInfoOidcComponent,
        PageMypageComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
        
    ],
    providers: [
        {
            provide: COMPOSITION_BUFFER_MODE,
            useValue: false
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }